import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';

const ExamplesPage = ({ data: { allMarkdownRemark: { edges } , site} }) => {
  return (
    <Layout title={site.siteMetadata.title}>
      <SEO title={site.siteMetadata.title}/>
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            <h1 className="title">Projektbeispiele</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className={'column is-10 is-offset-1'}>
          <div className={'columns is-multiline'}>
            {edges.map((data, i) => {
              const { node: { frontmatter, fields: { slug } } } = data;
              return (
                <div className={'column is-4'} key={slug}>
                  <Link to={slug} key={i}>
                    <div className="card card-equal-height">
                      <div className="card-image max-card">
                        {frontmatter.thumbnail &&
                            <figure className="image pt-5 is-4by3">
                              <Img
                                sizes={{ ...frontmatter.thumbnail.childImageSharp.fluid }}
                                fluid={frontmatter.thumbnail.childImageSharp.fluid}
                                style={{ maxHeight: 370 }}
                                imgStyle={{ width: 'auto', maxHeight: 350 }}
                                alt={frontmatter.title} objectFit="fit"/>
                            </figure>
                        }
                      </div>
                      <div className="card-content">
                        <div className="content">
                          <p className={'is-text is-size-5 mb-0'}>
                            {frontmatter.title}
                          </p>
                          <p className={'is-text is-size-6 mt-0'}>
                            {frontmatter.subtitle}
                          </p>
                          <div className="buttons">
                            <button className="button is-link is-occre is-jura">Details</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                </div>
              );
            }
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default ExamplesPage;

export const pageQuery = graphql`
    query ExamplesPage {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            sort: {order: DESC, fields: frontmatter___createdAt}
            filter: { frontmatter: { templateKey: { eq: "example" } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        subtitle
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 512, quality: 50) {
                                    ...GatsbyImageSharpFluid_withWebp,
                                }
                            }
                        }
                        description
                    }
                }
            }
        }
    }
`;
